div .toolbar {
    justify-content: start;
    align-items: center;
}

div .bottomToolbar {
    justify-content: space-between;
    align-items: space;
}

.coverImageContainer {
    min-width: 100%;
}

.coverImageContainer img {
    min-width: 40px;
}
