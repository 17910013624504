div .toolbar {
    justify-content: space-between;
    align-items: center;
}

.toolbar button {
    margin-right: var(--margin-internal);
}

.toolbar button:last-child {
    margin-right: 0;
}
