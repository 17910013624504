.button {
    width: 100%;
}

.button span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.buttonContainer {
    margin-bottom: 10px;
    margin-right: unset;
}

.buttonContainer:last-child {
    margin-bottom: 0;
    margin-right: unset;
}

@media screen and (min-device-width: 768px) {
    .buttonContainer {
        margin-right: 40px;
    }

    .button {
        width: unset;
    }
}