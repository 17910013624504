.wrapper {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.wrapper h1 {
  font-family: "DomaineDisplay";
  font-size: 20px;
}

.wrapper p img {
  object-fit: cover;
  width: 100%;
}

.wrapper img {
  width: 100%;
}

.wrapper p {
  color: var(--color-secondary);
  font-size: 14px;
}

@media screen and (min-device-width: 1024px) {
  .wrapper p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .wrapper p {
    color: var(--color-secondary);
    font-size: 18px;
  }

  .wrapper h1 {
    font-family: "DomaineDisplay";
    font-size: 26px;
  }
}
