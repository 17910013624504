.main {
    min-height: calc(100vh - 631px);
    margin-bottom: 50px;
    background: var(--color-white);
}

.noMargin {
    margin: 0;
}

@media screen and (min-width: 769px) { 
    .main {
        min-height: calc(100vh - 194px);
        background: var(--color-white);
    }
}

:root {
    --main-container-max-width: 1170px;
    --header-height: 58px;
    --internal-padding: 0.2rem;
    --regular-padding: 0.5rem;
    --font-size-page-description: 15px;
    --line-height-page-description: 21px;
}

@media (min-width: 992px) {
    :root {
        --font-size-page-description: 18px;
        --line-height-page-description: 26px;
    }
}