@import url("./colors.css");
@import url("./font.module.css");
@import url("./layout.module.css");

html,
body,
div#root {
    font-family: var(--font-family);
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.MuiButton-label span {
    padding-left: 0;
}

span[class=]

.ra-rich-text-input .ql-editor {
    font-family: var(--font-family) !important;
}

.firebaseui-idp-text {
    font-size: 12px !important;
}

.mdl-button {
    font-family: var(--font-family) !important;
}

div[class^="RaLoading-message-"], div[class*=" RaLoading-message-"] {
    font-family: var(--font-family);
}

.mid-container {
    width: 100%;
}

@media screen and (min-device-width: 600px) { 
    .mid-container {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }
}
