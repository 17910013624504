.container {
    padding-top: 80px;
    padding-bottom: 60px;
}

.subBox {
    padding-top: 50px !important;
}

.subBoxPrimary {
    font-size: 20px;
    text-align: left;
    font-family: "DomaineDisplay";
}

.subBoxSecondary {
    font-size: 18px;
    color: var(--color-secondary);
    text-align: left;
}

.pageBody p img {
    object-fit: cover;
    width: 100%;
}

.contentContainer{
    position: relative;
    z-index: 1;
}

@media screen and (min-device-width: 1024px) {
    .pageBody p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }

    .subBoxPrimary {
        font-size: 26px;
        text-align: left;
        font-family: "DomaineDisplay";
    }
    
    .subBoxSecondary {
        font-size: 14px;
        color: var(--color-secondary);
        text-align: left;
    }
}