.formHeader p {
    margin: 0 0 2rem;
    font-size: 1.5rem;
    font-family: var(--font-family-domaine);
}

.formHeader p::first-letter {
    text-transform: uppercase;
}

