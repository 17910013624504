.title {
    font-family: "GT-America";
    font-weight: 400 !important;
    margin-top: 85px;
    text-transform: uppercase;
    font-size: 12px !important;
    line-height: 18px;
}

@media (min-width: 769px) {
    .title {
        margin-top: 145px;
        font-size: var(--font-size-body)!important;
        line-height: var(--line-height-body-and-title) !important;
    }
}
