.text {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    margin: 0 0 var(--margin) 0;
    color: var(--color-gray-darker);
    line-height: 1.6;
}

.noMargin {
    margin: 0 !important
}

.center{
    text-align: center;
}
