.pageContainer {
    background-color: var(--color-white);
    width: 100vw;
}

.pageContainer > div {
    width: 100%;
}

.pageContainer > div > div {
    padding: 0 20px;
    max-width: var(--main-container-max-width);
    margin-left: auto;
    margin-right: auto;
}

.pageContainer * {
    box-sizing: border-box;
}

@media screen and (min-width: 481px) {
    .pageContainer > div > div {
        padding: 0 50px;
    }
}

@media screen and (min-width: 769px) {
    .pageContainer > div > div {
        padding: 0 90px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .pageContainer > div > div {
        padding: 0px 90px;
        max-width: none;
    }
}

@media screen and (min-width: 1350px) {
    .pageContainer > div > div {
        padding: 0px;
        max-width: var(--main-container-max-width);
    }
}