.tableBase {
    table-layout: fixed;
}

.tableBase thead {
    font-weight: 500;
    text-transform: uppercase;
}

.tableBase thead tr th span {
    color: var(--color-gray);
}

.tableBase thead tr th:nth-child(2) {
    width: 10%;
}

.tableBase tbody tr:hover {
    cursor: pointer;
}

.tableBase td:nth-child(3),th:nth-child(3), td:nth-child(2),th:nth-child(2) {
    width: 10%;
    text-align: left;
}