.selectInput {
    padding-left: 20px;
}

.formIteratorContainer {
    margin-top: 15px;
}

.inputContainer {
    width: 100% !important;
    padding: 18px 16px 0px 16px !important;
}

.addButton {
    background-color: transparent !important;
    border: 1px solid rgba(50, 50, 50, 0.5) !important;
    box-shadow: unset !important;
}

.addButton:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: unset !important;
}

.addButton span {
    color: black !important;
}