div .toolbar {
    justify-content: space-between;
    align-items: center;
}

.toolbar button {
    margin-right: var(--margin-internal);
    justify-content: center;
}

.toolbar button:last-child {
    margin-right: 0;
}

.rtfContainerMinHeight>div> :global(.ql-container)> :global(.ql-editor) {
    min-height: unset !important;
}

.savePreviewButton {
    margin-left: 20px !important;
}