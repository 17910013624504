:root  {
    --color-black: #000;
    --color-white: #fff;

    --color-danger: #f66168;
    --color-disabled: #d5d5d5;
    --color-success: #8AD6AF;
    --color-warning: #FAC362;
    --color-call-action: #A0C6D2;

    --color-secondary: #666666;
    --color-tertiary: #9A9A9A;

    --color-selection: #f0f0f0;
    --color-light-gray: #f2f2f2;
    --color-light-gray-2: #9E9E9E;
    --color-medium-gray: #d5d5d5;
    --color-gray: #808080;
    --color-dark-gray: #484848;

    --color-mark: #feffe6;
    --antd-wave-shadow-color: #000;
}