blockquote {
  padding: 10px 0px 10px 25px !important;
  margin: 0 0 25px !important;
  font-size: 18px;
  line-height: 26px;
  border-left: 5px solid #d5d5d5;
  display: block;
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  color: #666
}

.wrapper{
  margin-bottom: 25px !important;
}