.title {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
}

.title *{
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
    color: inherit;
}

@media (min-width: 768px) {
    .title {
        font-size: 54px !important;
        line-height: 64px !important;
        margin-top: 145px;
        margin-bottom: 20px !important;
    }

    .title *{
        font-size: 54px !important;
        line-height: 64px !important;
        margin-top: 145px;
        margin-bottom: 20px !important;
        color: inherit;
    }
}