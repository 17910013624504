.spacer {
    flex: 1
}

.header > div > button:nth-child(1) {
    display: block;
}

.header a {
    margin-left: unset;
}

@media screen and (min-width: 600px) /* Desktop */ {
    .header > div > button:nth-child(1) {
        display: none;
    }

    .header a {
        margin-left: 1.2rem;
    }
}
