.customColorInput :global(.MuiInputBase-input) {
    background-color: rgba(0, 0, 0, 0.04);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 19px 12px 6px;
}

.customColorInput :global(.MuiInput-formControl) {
    margin-top: unset;
}

.customColorInput :global(.MuiFormLabel-root) {
    margin-left: 1rem;
    margin-top: -0.5rem;
}

.customColorInput :global(.MuiInputLabel-shrink) {
    margin-left: 0.9rem;
    transform: translate(0, 12px) scale(0.75)
}

.customColorInput :global(.MuiFormHelperText-root) {
    margin-left: 14px;
    
}