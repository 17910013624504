@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap);
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --color-black: #000000;
    --color-gray: #757575;
    --color-gray-dark: #525252;
    --color-gray-light: #9E9E9E;
    --color-gray-lighter: #f8f8f8;

    --color-gray-sidebar-dark: #323232;
    --color-gray-sidebar: #D5D5D5;
    --color-gray-sidebar-light: #F0F0F0;

    --color-green-sucess: #8AD6AF;
    --color-blue-action: #A0C6D2;
    --color-yellow-warning: #FAC362;;
    --color-red-danger: #F66168;

    --color-white: #FFFFFF;
}

@font-face {
    font-family: "GTAmerica-Thin";
    src: url(/static/media/GT-America-Extended-Thin.06f3bae7.ttf);
}

@font-face {
    font-family: "GTAmerica-Regular";
    src: url(/static/media/GT-America-Extended-Regular.3f4cae66.ttf);
}

@font-face {
    font-family: "GTAmerica-Bolder";
    src: url(/static/media/GT-America-Extended-Black.e8d42352.ttf);
}

@font-face {
    font-family: "GTAmerica-Bold";
    src: url(/static/media/GT-America-Extended-Bold.c033a027.ttf);
}

@font-face {
    font-family: "GTAmerica-Medium";
    src: url(/static/media/GT-America-Extended-Medium.e0fcd260.ttf);
}

@font-face {
    font-family: "GTAmerica-Ultra-Light";
    src: url(/static/media/GT-America-Extended-Ultra-Light.a79be711.ttf);
}

@font-face {
    font-family: "GTAmerica-Light";
    src: url(/static/media/GT-America-Extended-Light.36eebced.ttf);
}

@font-face {
    font-family: "DomaineDisplayWeb";
    src: url(/static/media/DomaineDisplayWeb-Regular.db08da3e.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 300;
    src: url(/static/media/GT-America-Extended-Light.1a54cec1.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 400;
    src: url(/static/media/GT-America-Extended-Regular.fe3fe671.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 500;
    src: url(/static/media/GT-America-Extended-Medium.0444e71f.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 600;
    src: url(/static/media/GT-America-Extended-Bold.17b18696.woff);
}


@font-face {
    font-family: "DomaineDisplay";
    font-weight: 400;
    src: url(/static/media/DomaineDisplayWeb-Regular.db08da3e.woff);
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 500;
    src: url(/static/media/DomaineDisplayWeb-Medium.afa42e1e.woff);
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 600;
    src: url(/static/media/DomaineDisplayWeb-Semibold.eef8e40f.woff);
}

:root {
    --font-family: "GTAmerica-Light";
    --font-family-domaine: "DomaineDisplayWeb";

    --ratio: 1.15;
    --p: 0.85rem;
    --input: var(--p);

    --caption: calc(var(--p) / var(--ratio));
    --subcaption: calc(var(--caption) / var(--ratio));

    /* FONT WEIGHT */
    --font-weight-bold: 800 !important;
    --font-weight-semibold: 700 !important;
    --font-weight-medium: 600 !important;
    --font-weight: 500 !important;
    --font-weight-regular-secondary: 400 !important;
    --font-weight-regular: 300 !important;
    --font-weight-light: 200 !important;
    --font-weight-extra-light: 100 !important;

    --font-size-h1: 3.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.15rem;
    --font-size-subtitle: 1.15rem;
    --font-size-body: 1rem;
    --font-size-caption: 0.85rem;
}

:root {
    --padding-small: 0.5rem;
    --padding: 0.7rem;  
    --padding-medium: 1.5rem;
    --padding-large: 2rem;

    --margin-internal: 0.5rem;
    --margin-small: 0.75rem;
    --margin: 1rem;
    --margin-medium: 1.75rem;
    --margin-large: 2.25rem;
    --margin-subpage: 3.5rem;

    --box-shadow-hover: 0px 2px 1px 0px var(--color-gray-lighter);
    --box-radius: 2px;
    --button-radius: 3px;

    --radius: 3px;
}

html,
body,
div#root {
    font-family: var(--font-family);
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.MuiButton-label span {
    padding-left: 0;
}

span[class=]

.ra-rich-text-input .ql-editor {
    font-family: var(--font-family) !important;
}

.firebaseui-idp-text {
    font-size: 12px !important;
}

.mdl-button {
    font-family: var(--font-family) !important;
}

div[class^="RaLoading-message-"], div[class*=" RaLoading-message-"] {
    font-family: var(--font-family);
}

.mid-container {
    width: 100%;
}

@media screen and (min-device-width: 600px) { 
    .mid-container {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }
}

.pageTitle_title__1B6HB {
    font-family: "DomaineDisplayWeb";
}
.richTextInput_customRichTextInput__2GUzw .ql-editor {
    min-height: 300px;
}

.richTextInput_customRichTextInput__2GUzw {
    min-width: 100%;
}

.richTextInput_customRichTextInput__2GUzw .ql-editor {
    min-height: 200px;
}

.richTextInput_customRichTextInput__2GUzw .ql-editor p {
    margin-bottom: 0px !important;
}

.richTextInput_customRichTextInput__2GUzw .ql-editor h1,
.richTextInput_customRichTextInput__2GUzw .ql-editor h2,
.richTextInput_customRichTextInput__2GUzw .ql-editor h3 {
    margin-top: 0px !important;
}
.popper_root__2_9VF {
    padding: 20px;
}

.popperForm_body__143Bf {
    margin-bottom: 2rem;
}

.text_text__1Fq9m {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    margin: 0 0 var(--margin) 0;
    color: var(--color-gray-darker);
    line-height: 1.6;
}

.text_noMargin__3qwCw {
    margin: 0 !important
}

.text_center__1Q1Jt{
    text-align: center;
}

.popperFormHeader_formHeader__3mkhx p {
    margin: 0 0 2rem;
    font-size: 1.5rem;
    font-family: var(--font-family-domaine);
}

.popperFormHeader_formHeader__3mkhx p::first-letter {
    text-transform: uppercase;
}


div .edit_toolbar__OgCtb {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_toolbar__OgCtb button {
    margin-right: var(--margin-internal);
}

.edit_toolbar__OgCtb button:last-child {
    margin-right: 0;
}

.edit_container__1c6wJ>div>div>.edit_input__1sPea {
    min-width: 256px;
}
.filter_search__x4T1a {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__2RCw- {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__2RCw- div {
        max-width: 100%;
    }

    .filter_form__2RCw- > div:last-child {
        display: none;
    }

    .filter_form__2RCw- > div:first-child {
        width: 100%;
    }

    .filter_form__2RCw- > div:first-child div:last-child {
        display: none;
    }

    .filter_form__2RCw- > div:first-child div:first-child {
        width: 100%;
    }
}
.Table_tableBase__2Kmag {
    table-layout: fixed;
}

.Table_tableBase__2Kmag thead {
    font-weight: 500;
    text-transform: uppercase;
}

.Table_tableBase__2Kmag thead tr th span {
    color: var(--color-gray);
}

.Table_tableBase__2Kmag thead tr th:nth-child(2) {
    width: 10%;
}

.Table_tableBase__2Kmag tbody tr:hover {
    cursor: pointer;
}

.Table_tableBase__2Kmag td:nth-child(3),th:nth-child(3), td:nth-child(2),th:nth-child(2) {
    width: 10%;
    text-align: left;
}
.StatusText_status__16H8c {
    display: inline-block;
    padding: 3px 4px;
    font-size: 0.9rem;
    line-height: 1em;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    text-transform: capitalize;
}

.StatusText_draft__24vLi {
    background: var(--color-red-danger);
    color: var(--color-white);
}

.StatusText_published__3zGN8 {
    background: var(--color-green-sucess);
    color: var(--color-white);
}

.StatusText_unpublished__3dBZi, .StatusText_archived__cqRGr {
    background: var(--color-blue-action) ;
    color: var(--color-white);
}


.SubTitle_subTitle__3cf3Z {
    color: var(--color-gray);
    font-size: 0.9rem;
}

.Title_title__3Abyh {
    margin: 0 0 3px;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: var(--font-family-domaine);
}

@media only screen and (max-width: 599px) {
    .list_filterContainer__1DCOU > div:first-child > div {
        display: none;
    }
}

.filter_search__3OoB- {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__2Pixi {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__2Pixi div {
        max-width: 100%;
    }

    .filter_form__2Pixi > div:last-child {
        display: none;
    }

    .filter_form__2Pixi > div:first-child {
        width: 100%;
    }

    .filter_form__2Pixi > div:first-child div:last-child {
        display: none;
    }

    .filter_form__2Pixi > div:first-child div:first-child {
        width: 100%;
    }

}


.ColorText_color__1FfKa {
    padding: 3px 4px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}
@media only screen and (max-width: 599px) {
    .tag_filterContainer__3Df1O > div:first-child > div {
        display: none;
    }
}
.ColorInput_customColorInput__2OtQR .MuiInputBase-input {
    background-color: rgba(0, 0, 0, 0.04);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 19px 12px 6px;
}

.ColorInput_customColorInput__2OtQR .MuiInput-formControl {
    margin-top: unset;
}

.ColorInput_customColorInput__2OtQR .MuiFormLabel-root {
    margin-left: 1rem;
    margin-top: -0.5rem;
}

.ColorInput_customColorInput__2OtQR .MuiInputLabel-shrink {
    margin-left: 0.9rem;
    -webkit-transform: translate(0, 12px) scale(0.75);
            transform: translate(0, 12px) scale(0.75)
}

.ColorInput_customColorInput__2OtQR .MuiFormHelperText-root {
    margin-left: 14px;
    
}
div .create_toolbar__3nSha {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__gzSDX {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__1QRFu {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__nBvAt {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_toolbar__nBvAt button {
    margin-right: var(--margin-internal);
    -webkit-justify-content: center;
            justify-content: center;
}

.edit_toolbar__nBvAt button:last-child {
    margin-right: 0;
}

.edit_rtfContainerMinHeight__2MQAE>div> .ql-container> .ql-editor {
    min-height: unset !important;
}

.edit_savePreviewButton__XCjds {
    margin-left: 20px !important;
}
.html_textInputContainer__399qq > div > div > div > .MuiInputBase-input {
    min-height: 200px !important;
}
.customIterator_inputContainer__2l8-I {
    width: 100% !important;
    padding: 18px 16px 0px 16px !important;
}

.customIterator_addButton__UrJs- {
    background-color: transparent !important;
    border: 1px solid rgba(50, 50, 50, 0.5) !important;
    box-shadow: unset !important;
}

.customIterator_addButton__UrJs-:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: unset !important;
}

.customIterator_addButton__UrJs- span svg {
    fill: black !important;
}
.typography_singleLineRtfTypographyContainer__3aENu>div> .ql-container> .ql-editor {
    min-height: unset !important;
}
.iterator_selectInput__2QFZE {
    padding-left: 20px;
}

.iterator_formIteratorContainer__3GCY9 {
    margin-top: 15px;
}

.iterator_inputContainer__3nb9j {
    width: 100% !important;
    padding: 18px 16px 0px 16px !important;
}

.iterator_addButton__2MEyK {
    background-color: transparent !important;
    border: 1px solid rgba(50, 50, 50, 0.5) !important;
    box-shadow: unset !important;
}

.iterator_addButton__2MEyK:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: unset !important;
}

.iterator_addButton__2MEyK span {
    color: black !important;
}
.filter_search__2VrIg {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__3fGdN {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__3fGdN div {
        max-width: 100%;
    }

    .filter_form__3fGdN > div:last-child {
        display: none;
    }

    .filter_form__3fGdN > div:first-child {
        width: 100%;
    }

    .filter_form__3fGdN > div:first-child div:last-child {
        display: none;
    }

    .filter_form__3fGdN > div:first-child div:first-child {
        width: 100%;
    }
}
@media only screen and (max-width: 599px) {
    .list_filterContainer__3ivZH > div:first-child > div {
        display: none;
    }
}
div .create_toolbar__1oyq4 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__Akze- {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__2M6X3 {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__GbKPL {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__3boaf {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__3boaf div {
        max-width: 100%;
    }

    .filter_form__3boaf > div:last-child {
        display: none;
    }

    .filter_form__3boaf > div:first-child {
        width: 100%;
    }

    .filter_form__3boaf > div:first-child div:last-child {
        display: none;
    }

    .filter_form__3boaf > div:first-child div:first-child {
        width: 100%;
    }
}


@media only screen and (max-width: 599px) {
    .partnershipCategory_filterContainer__1JCXE > div:first-child > div {
        display: none;
    }
}

div .edit_toolbar__1OEfs {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_toolbar__1OEfs button {
    margin-right: var(--margin-internal);
}

.edit_toolbar__1OEfs button:last-child {
    margin-right: 0;
}

.filter_search__1G2wX {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__ApANr {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__ApANr div {
        max-width: 100%;
    }
    .filter_form__ApANr > div:last-child {
        display: none;
    }

    .filter_form__ApANr > div:first-child {
        width: 100%;
    }

    .filter_form__ApANr > div:first-child div:last-child {
        display: none;
    }

    .filter_form__ApANr > div:first-child div:first-child {
        width: 100%;
    }
}


@media only screen and (max-width: 599px) {
    .list_filterContainer__i3oVM > div:first-child > div {
        display: none;
    }
}
.CustomSidebar_MuiDrawer-root__3K5vr  a, .CustomSidebar_MuiDrawer-root__3K5vr  li {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

@media screen and (max-width: 599px) {
    .CustomSidebar_MuiDrawer-root__3K5vr a:first-child {
        margin-top: 1rem;
    }
}
.logo_logo__3UN4X {
    display: block;
    max-width: 60px;
    width: 60px;
    height: 42px;
    background: url(/static/media/plmj.9f366ab1.svg) no-repeat;
    background-size: 60px 42px;
}
  
.Header_spacer__2LbXt {
    -webkit-flex: 1 1;
            flex: 1 1
}

.Header_header__3SccP > div > button:nth-child(1) {
    display: block;
}

.Header_header__3SccP a {
    margin-left: unset;
}

@media screen and (min-width: 600px) /* Desktop */ {
    .Header_header__3SccP > div > button:nth-child(1) {
        display: none;
    }

    .Header_header__3SccP a {
        margin-left: 1.2rem;
    }
}

div .edit_toolbar__1yKY- {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_toolbar__1yKY- button {
    margin-right: var(--margin-internal);
}

.edit_toolbar__1yKY- button:last-child {
    margin-right: 0;
}

.edit_container__1iTvy>div>div>.edit_input__DSxol {
    min-width: 256px;
}

.edit_imageText__1W3jU {
    text-align: justif !important;
    width: 110% !important;
}
.filter_search__3-Gmv {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__6HKSx {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__6HKSx div {
        max-width: 100%;
    }

    .filter_form__6HKSx > div:last-child {
        display: none;
    }

    .filter_form__6HKSx > div:first-child {
        width: 100%;
    }

    .filter_form__6HKSx > div:first-child div:last-child {
        display: none;
    }

    .filter_form__6HKSx > div:first-child div:first-child {
        width: 100%;
    }
}
@media only screen and (max-width: 599px) {
    .list_filterContainer__8s3fR > div:first-child > div {
        display: none;
    }
}

div .create_toolbar__3Obb1 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__20iza {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__1W1yh {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__1Loip {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__2qDXK {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__2qDXK div {
        max-width: 100%;
    }

    .filter_form__2qDXK > div:last-child {
        display: none;
    }

    .filter_form__2qDXK > div:first-child {
        width: 100%;
    }

    .filter_form__2qDXK > div:first-child div:last-child {
        display: none;
    }

    .filter_form__2qDXK > div:first-child div:first-child {
        width: 100%;
    }
}


@media only screen and (max-width: 599px) {
    .list_filterContainer__GoUAZ > div:first-child > div {
        display: none;
    }
}
div .edit_toolbar__37Fw2 {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_toolbar__37Fw2 button {
    margin-right: var(--margin-internal);
}

.edit_toolbar__37Fw2 button:last-child {
    margin-right: 0;
}

.edit_container__1d71c>div>div>.edit_input__3Y0Id {
    min-width: 256px;
}


div .create_toolbar__2515q {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__eQSBl {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__2uxib {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__3_ttW {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__2T7i6 {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__2T7i6 div {
        max-width: 100%;
    }

    .filter_form__2T7i6 > div:last-child {
        display: none;
    }

    .filter_form__2T7i6 > div:first-child {
        width: 100%;
    }

    .filter_form__2T7i6 > div:first-child div:last-child {
        display: none;
    }

    .filter_form__2T7i6 > div:first-child div:first-child {
        width: 100%;
    }
}


@media only screen and (max-width: 599px) {
    .list_filterContainer__2DVWx > div:first-child > div {
        display: none;
    }
}

div .create_toolbar__ynVMJ {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__244lM {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__2tYHy {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__1zhNe {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__IpG1Y {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__IpG1Y div {
        max-width: 100%;
    }

    .filter_form__IpG1Y > div:last-child {
        display: none;
    }

    .filter_form__IpG1Y > div:first-child {
        width: 100%;
    }

    .filter_form__IpG1Y > div:first-child div:last-child {
        display: none;
    }

    .filter_form__IpG1Y > div:first-child div:first-child {
        width: 100%;
    }
}


@media only screen and (max-width: 599px) {
    .list_filterContainer__1liNh > div:first-child > div {
        display: none;
    }
}

.menuFormGrid_menuGrid__11As8 > div {
    box-sizing: border-box;
    padding-right: 8px;
}

.menuFormGrid_menuGrid__11As8 > div ,
.menuFormGrid_dateGrid__1jbyk > div {
    width: 100%;
}
div .setupHomepage_toolbar__6ZIq4 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
.musicFormGrid_musicGrid__2yt58 > div {
    width: 100%;
}

.appsFormGrid_hotTopicGrid__2g8kK > div {
    box-sizing: border-box;
    padding-right: 8px;
}
.iterator_inputContainer__3SWoa {
    width: 100% !important;
    padding: 18px 16px 0px 16px !important;
}

.iterator_addButton__1qqPa {
    background-color: transparent !important;
    border: 1px solid rgba(50, 50, 50, 0.5) !important;
    box-shadow: unset !important;
}

.iterator_addButton__1qqPa:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: unset !important;
}

.iterator_addButton__1qqPa span svg {
    fill: black !important;
}
.kioskFormGrid_hotTopicGrid__2x_Yz > div {
    box-sizing: border-box;
    padding-right: 8px;
}
div .plmjplus_toolbar__EMOB1 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .agenda_toolbar__1D5Mp {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .whoiswho_toolbar__1r5Mq {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__2_aI8 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__3_ZCY {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
div .create_toolbar__1J9N- {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .create_toolbar__4iXd_ {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__2xuvd {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolBar__qCsxv {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__2GseL {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__34H2R {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__34H2R div {
        max-width: 100%;
    }

    .filter_form__34H2R > div:last-child {
        display: none;
    }

    .filter_form__34H2R > div:first-child {
        width: 100%;
    }

    .filter_form__34H2R > div:first-child div:last-child {
        display: none;
    }

    .filter_form__34H2R > div:first-child div:first-child {
        width: 100%;
    }

}


div .list_toolbar__3lNuT {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .teams_toolbar__1fT30 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

.teams_rtfContainerTab__7jVF1, .teams_rtfContainerDescription__2Zjox {
    min-width: 100%
}

.teams_rtfContainerTab__7jVF1 .ql-editor {
    min-height: 45px;
}

.teams_rtfContainerDescription__2Zjox .ql-editor {
    min-height: 135px;
}
.TeamsList_table__gpymS table thead tr th:nth-child(2) {
    width: 20% !important;
}
.filter_search__3GKw5 {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__3Wu84 {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__3Wu84 div {
        max-width: 100%;
    }

    .filter_form__3Wu84 > div:last-child {
        display: none;
    }

    .filter_form__3Wu84 > div:first-child {
        width: 100%;
    }

    .filter_form__3Wu84 > div:first-child div:last-child {
        display: none;
    }

    .filter_form__3Wu84 > div:first-child div:first-child {
        width: 100%;
    }

}


div .list_toolbar__3vqQ2 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .create_toolbar__ivehv {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__28tWP {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__t-i0v {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
div .broadcast_toolbar__3yMDO {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

.broadcast_rtfContainerTab__2Xd1A, .broadcast_rtfContainerDescription__SigNu {
    min-width: 100%
}

.broadcast_rtfContainerTab__2Xd1A .ql-editor {
    min-height: 45px;
}

.broadcast_rtfContainerDescription__SigNu .ql-editor {
    min-height: 135px;
}
div .create_toolbar__3RVMu {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
div .edit_toolbar__Tyzdq {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__1YHOT {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.filter_search__lkCuE {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__1riO3 {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__1riO3 div {
        max-width: 100%;
    }

    .filter_form__1riO3 > div:last-child {
        display: none;
    }

    .filter_form__1riO3 > div:first-child {
        width: 100%;
    }

    .filter_form__1riO3 > div:first-child div:last-child {
        display: none;
    }

    .filter_form__1riO3 > div:first-child div:first-child {
        width: 100%;
    }

}


div .list_toolbar__3tUtA {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

.filter_search__2_fGH {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__1AA3m {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__1AA3m div {
        max-width: 100%;
    }

    .filter_form__1AA3m > div:last-child {
        display: none;
    }

    .filter_form__1AA3m > div:first-child {
        width: 100%;
    }

    .filter_form__1AA3m > div:first-child div:last-child {
        display: none;
    }

    .filter_form__1AA3m > div:first-child div:first-child {
        width: 100%;
    }

}


div .list_toolbar__30yVW {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_toolbar__3YMHS {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__1i-ln {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: space;
            align-items: space;
}

.edit_coverImageContainer__2lLAg {
    min-width: 100%;
}

.edit_coverImageContainer__2lLAg img {
    min-width: 40px;
}

div .order_toolbar__1hK9o {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}
.iterator_inputContainer__SmYwv {
    width: 100% !important;
    padding: 18px 16px 0px 16px !important;
}

.iterator_addButton__3rDMm {
    background-color: transparent !important;
    border: 1px solid rgba(50, 50, 50, 0.5) !important;
    box-shadow: unset !important;
}

.iterator_addButton__3rDMm:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    box-shadow: unset !important;
}

.iterator_addButton__3rDMm span svg {
    fill: black !important;
}
div .edit_toolbar__3_WVP {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

div .edit_bottomToolbar__1cZwY {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.edit_coverImageContainer__1G84S {
    min-width: 100%;
}

.edit_coverImageContainer__1G84S img {
    min-width: 40px;
}

.filter_search__Nyfy0 {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .filter_form__1ibGp {
        width: 100%;
        padding-right: 16px;
    }

    .filter_form__1ibGp div {
        max-width: 100%;
    }

    .filter_form__1ibGp > div:last-child {
        display: none;
    }

    .filter_form__1ibGp > div:first-child {
        width: 100%;
    }

    .filter_form__1ibGp > div:first-child div:last-child {
        display: none;
    }

    .filter_form__1ibGp > div:first-child div:first-child {
        width: 100%;
    }

}


div .list_toolbar__26Q63 {
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
}

.pagesPreview_container__2rJTD {
    padding-top: 80px;
    padding-bottom: 60px;
}

.pagesPreview_subBox__37msZ {
    padding-top: 50px !important;
}

.pagesPreview_subBoxPrimary__3TmFN {
    font-size: 20px;
    text-align: left;
    font-family: "DomaineDisplay";
}

.pagesPreview_subBoxSecondary__20Cz0 {
    font-size: 18px;
    color: var(--color-secondary);
    text-align: left;
}

.pagesPreview_pageBody__15MCn p img {
    object-fit: cover;
    width: 100%;
}

.pagesPreview_contentContainer__Llf1I{
    position: relative;
    z-index: 1;
}

@media screen and (min-device-width: 1024px) {
    .pagesPreview_pageBody__15MCn p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }

    .pagesPreview_subBoxPrimary__3TmFN {
        font-size: 26px;
        text-align: left;
        font-family: "DomaineDisplay";
    }
    
    .pagesPreview_subBoxSecondary__20Cz0 {
        font-size: 14px;
        color: var(--color-secondary);
        text-align: left;
    }
}
.button_button__3dBn- {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    border-radius: 0 !important;
    transition: none !important;
    height: unset !important;
    padding: 10px 24px !important;
}

.button_primary-enabled__2BwRa {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-black) !important;
}

.button_primary-danger__3GlBL {
    background-color: var(--color-danger) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-danger) !important;
}

.button_primary-disabled__1RcTq {
    background-color: var(--color-disabled) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-disabled) !important;
}

.button_default-enabled__3Ztio {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-black) !important;
}

.button_default-danger__3zSft {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-danger) !important;
}

.button_default-disabled__3Uzin {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-disabled) !important;
}

.button_text-enabled__1eEg- {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-white) !important;
    box-shadow: none !important;
}

.button_text-danger__3Kp7_ {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-white) !important;
}

.button_text-disabled__2xk9Z {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-white) !important;
}

@media screen and (min-width: 768px) {

    .button_button__3dBn- {
        font-size: 14px !important;
    }

}
.b2_body__3DWqc {
    color: var(--color-black) !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.pagination_phonePaginationContainer__2j9iu, .pagination_paginationContainer__1gT_y {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
}

.pagination_phoneBottomReachedMessage__4R8J7 {
    -webkit-justify-content: center !important;
            justify-content: center !important;
    font-weight: 600 !important;
    font-family: "DomaineDisplay";
}

.pagination_paginationContainer__1gT_y .ant-pagination-prev {
    width: 40px !important;
    height: 50px !important;
    margin-right: 10px;
}

.pagination_paginationContainer__1gT_y .ant-pagination-next {
    width: 35px !important;
    height: 50px !important;
    margin: 0;
}

.pagination_paginationContainer__1gT_y .ant-pagination-prev > svg {
    width: 100%;
    height: 50px;
}

.pagination_paginationContainer__1gT_y .ant-pagination-next > svg {
    width: 100%;
    height: 50px;
}

.pagination_paginationContainer__1gT_y .ant-pagination-disabled > li > svg > path {
    stroke: #D2D2D2 !important;
}

.pagination_paginationContainer__1gT_y .ant-pagination-disabled > svg > path {
    stroke: #D2D2D2 !important;
}

.pagination_paginationContainer__1gT_y .ant-pagination-simple-pager {
    font-family: "DomaineDisplay";
    font-weight: 600;
    font-size: 20px !important;
    color: #979797 !important;
    height: auto !important;
    margin-right: 0px !important;
    width: 45%;
}

.pagination_paginationContainer__1gT_y .ant-pagination-simple-pager > input {
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 40px !important;
    border: none !important;
    cursor: default;
    pointer-events: none;
    width: 55% !important;
    margin: unset !important;
    padding: unset !important;
}

.pagination_paginationContainer__1gT_y .ant-pagination-simple-pager > input:disabled {
    background: unset !important;
    color: #D2D2D2 !important;
}

.pagination_paginationContainer__1gT_y .ant-pagination-slash {
    margin: 0 5px 0 0;
}

.pagination_paginationContainer__1gT_y>ul>li:nth-child(2)>input {
    -webkit-background-clip: text;
            background-clip: text;
}
.h5_title__2pjCb{
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 20px !important;
}
.overline_overline__2YTO- {
    color: var(--color-black) !important;
    font-size: 12px !important;
    line-height: 18px;
}
.postCard_cardContainer__1J38P {
    max-width: 270px;
}

.postCard_cardContainer__1J38P .postCard_cover__3QKup {
    max-height: 230px;
    border: 1px solid #EBEBEB;
    border-bottom: unset;
}

.postCard_cardContainer__1J38P .ant-card-body {
    padding: 15px;
    border-top: unset;
    cursor: pointer;
    border-bottom: 1px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
}

.postCard_cardContainer__1J38P .ant-card-meta-title {
    font-family: "GT-America";
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    height: 52px;
    margin-bottom: 10px !important;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.postCard_cardContainer__1J38P .postCard_lastUpdated__1Tp1a {
    font-family: "GT-America";
    font-size: 10px !important;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary);
    margin-top: unset;
}

.postCard_cardContainer__1J38P .postCard_tagTitle__3d56w {
    font-family: "GT-America";
    font-size: 12px;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary) !important;
    margin-top: unset;
    margin-bottom: 5px !important;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU .ant-card-cover::before {
    content: "";
    position: absolute;
    left: 0; 
    right: 0;
    top: 0; 
    bottom: 0;
    max-height: 230px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/static/media/play.7255a1c9.svg);
}

@media screen and (min-width: 769px) {
    .postCard_cardContainer__1J38P {
        max-width: 270px;
    }

    .postCard_cardContainer__1J38P .postCard_cover__3QKup {
        max-height: 230px;
    }

    .postCard_cardContainer__1J38P .postCard_lastUpdated__1Tp1a {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 992px) {
    .postCard_cardContainer__1J38P {
        max-width: 245px;
    }

    .postCard_cardContainer__1J38P .postCard_cover__3QKup, .postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU .ant-card-cover::before {
        max-height: 215px;
    }
}

@media screen and (min-width: 1200px) {
    .postCard_cardContainer__1J38P {
        max-width: 225px;
    }

    .postCard_cardContainer__1J38P .postCard_cover__3QKup, .postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU .ant-card-cover::before {
        max-height: 200px;
    }
}

@media screen and (min-width: 1440px) {
    .postCard_cardContainer__1J38P {
        max-width: 270px;
    }

    .postCard_cardContainer__1J38P .postCard_cover__3QKup, .postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU .ant-card-cover::before {
        max-height: 230px;
    }
}

@media (hover: hover) {
    .postCard_cardContainer__1J38P {
        cursor: pointer;
    }
    
    .postCard_cardContainer__1J38P:hover .postCard_cover__3QKup {
        border-bottom: 1px solid var(--color-black) !important;
    }

    .postCard_cardContainer__1J38P:hover {
        background-color: var(--color-black);
    }
    
    .postCard_cardContainer__1J38P:hover .ant-card-meta-title, .postCard_cardContainer__1J38P:hover .postCard_lastUpdate__ROSY9, .postCard_cardContainer__1J38P:hover .ant-card-body {
        color: var(--color-white) !important;
    }

    .postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU:hover g {
        stroke: var(--color-white) !important;
    }

    .postCard_cardContainer__1J38P.postCard_cardThumb__c3aoU:hover g path {
        stroke: var(--color-white) !important;
    } 

    .postCard_cardContainer__1J38P:hover > div:nth-child(2) {
        border-bottom: 1px solid var(--color-black);
        border-left: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
    }
}

.categoryTitle_title__22X8_ {
    font-family: "GT-America";
    font-weight: 400 !important;
    margin-top: 85px;
    text-transform: uppercase;
    font-size: 12px !important;
    line-height: 18px;
}

@media (min-width: 769px) {
    .categoryTitle_title__22X8_ {
        margin-top: 145px;
        font-size: var(--font-size-body)!important;
        line-height: var(--line-height-body-and-title) !important;
    }
}

.b1_body__2B2ox {
    font-family: "GT-America";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 16px !important;
}

.filterDesktop_menuFilterContainer__bQPvH {
    padding: 20px !important;
    top: 15px;
}

.filterDesktop_menuFilterContainer__bQPvH > .ant-dropdown-menu-item:first-child {
    margin-bottom: 20px;
}

.filterDesktop_menuFilterContainer__bQPvH > .ant-dropdown-menu-item {
    padding: 0;
}

.filterDesktop_menuFilterContainer__bQPvH > .ant-dropdown-menu-item > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .filterDesktop_selectGroup__1kTGT {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.filterDesktop_selectGroup__1kTGT > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filterDesktop_filterContainer__3xRyV {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.filterDesktop_filterContainer__3xRyV > div > .filterDesktop_divider__6h9ih {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.filterDesktop_pointer__3G4Wr {
    cursor: pointer;
}

.filterDesktop_filterLabel__2hFOw {
    display: block;
    width: 100%;
}

.filterDesktop_filterLabel__2hFOw > span {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.filterDesktop_chevron__qdOb3 {
    margin-left: 15px;
    width: 14px;
}

.filterDesktop_clearButton__2brfq {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.filterDesktop_menuFilterContainer__bQPvH > .ant-dropdown-menu-item:hover {
    background-color: unset
}

.filterDesktop_dropdown__39TZW {
    padding-right: 20px;
    width: 290px;
    display: -webkit-flex;
    display: flex;
}

.filterDesktop_selectGroup__1kTGT > .ant-checkbox-wrapper {
    margin-left: 0;
}
.filterMobile_dropdown__1h_aL > span {
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--color-black);
}

.filterMobile_dropdown__1h_aL > span > span {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.filterMobile_label__2DCif {
    font-weight: 500;
    font-family: "DomaineDisplay";
    font-size: 22px !important;
    margin-bottom: 10px;
}

.filterMobile_dropdownOverlay__r7jXL > div {
    margin-top: -4px;
}

.filterMobile_dropdownOverlay__r7jXL .ant-dropdown-menu-item {
    background-color: var(--color-white);
}

.filterMobile_dropdownOverlay__r7jXL > div .ant-checkbox-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.filterMobile_dropdownOverlay__r7jXL > div > div:nth-child(2) .ant-checkbox-wrapper {
    margin: 9px 0;
}

.filterMobile_clearButton__3p9B3 {
    font-weight: 600;
}

.filterMobile_clearAllDisabled__1y3QS {
    color: var(--color-medium-gray);
}


.postsGrid_resultsContainer__3xTCY {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
}

.postsGrid_resultsContainer__3xTCY > span {
    color: var(--color-tertiary) !important;
}

.postsGrid_date__OEqik {
    font-size: 13px !important;
}

.postsGrid_overline__GT1WG{
    text-transform: uppercase;
}

.postsGrid_overlineContainer__o7eRV{
    margin-bottom: 5px !important;
}

.postsGrid_cardContainer__1h2vt {
    margin-bottom: 40px !important;
    max-width: 270px !important;
    margin-left: auto;
    margin-right: auto;
}

.postsGrid_faded__18VKm {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
}

.postsGrid_itemBody__2As_z, .postsGrid_itemBody__2As_z > * {
    margin-bottom: 10px !important;
    color: var(--color-secondary) !important;
    font-size: 14px;
}

.postsGrid_listWrap__MACfP{
    margin-top: 45px !important; 
}

.postsGrid_title__1dJWI {
    margin-top: 0px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
}

.postsGrid_image__3mD5C {
    max-width: 100%;
    height: 230px;
    margin-bottom: 5px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.postsGrid_link__3RUCB:hover .postsGrid_title__1dJWI {
    -webkit-text-decoration: underline solid black from-font !important;
            text-decoration: underline solid black from-font !important;
    text-underline-offset: 4px !important;
}

.postsGrid_link__3RUCB:hover .postsGrid_image__3mD5C {
    opacity: 0.6;
}

.postsGrid_pagination__8GDE- {
    width: auto;
    margin-right: -17px;
}

.postsGrid_pagination__8GDE- > ul{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.postsGrid_mainContainer__2M6NL {
    margin-bottom: 75px !important;
}

.postsGrid_searchInputContainer__3Jd4_ {
    width: 100%;
    margin-bottom: 40px;
}

.postsGrid_searchInputContainer__3Jd4_ > .postsGrid_searchInput__2TYZU {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.postsGrid_searchInput__2TYZU input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 22px;
    line-height: 30px;
}

.postsGrid_searchInput__2TYZU input::-webkit-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.postsGrid_searchInput__2TYZU input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.postsGrid_searchInput__2TYZU input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.postsGrid_searchInput__2TYZU .ant-input-prefix {
    margin-right: unset;
}

.postsGrid_searchInputIcon__2wlDh {
    width: 22px;
    height: 22px;
    margin-right: 18px;
    margin-left: 13px;
}

.postsGrid_tabMenuContainer__2GD5s {
    margin-top: 30px;
    width: 100%;
    margin-bottom: 20px;
}

.postsGrid_noMarginBottom__2Okda {
    margin-bottom: 0;
}

.postsGrid_divider__1FUh8 {
    position: absolute;
    width: 100vw;
    left: -20px;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 30px;
}

@media screen and (min-width: 481px) {
    .postsGrid_divider__1FUh8 {
        left: -50px;
    }
}

@media screen and (min-width: 769px) {
    .postsGrid_divider__1FUh8 {
        left: -90px;
    }

    .postsGrid_cardContainer__1h2vt {
        max-width: 270px !important;
    }

    .postsGrid_image__3mD5C {
        height: 230px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .postsGrid_divider__1FUh8 {
        left: calc((100vw - var(--main-container-max-width) + 180px) / -2);
    }
}

@media screen and (min-width: 992px) {
    .postsGrid_cardContainer__1h2vt {
        max-width: 245px;
    }

    .postsGrid_image__3mD5C {
        height: 215px;
    }
}

@media (min-width: 1009px) {
    .postsGrid_displayMobile__2CB_o {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .postsGrid_cardContainer__1h2vt {
        max-width: 225px;
    }

    .postsGrid_image__3mD5C {
        height: 200px;
    }
}


@media screen and (min-width: 1350px) {
    .postsGrid_divider__1FUh8 {
        left: calc((100vw - var(--main-container-max-width)) / -2) ;
    }
}

@media screen and (min-width: 1440px) {
    .postsGrid_cardContainer__1h2vt {
        max-width: 270px;
    }

    .postsGrid_image__3mD5C {
        max-height: 230px;
    }
}

.pageContainer_pageContainer__omPgW {
    background-color: var(--color-white);
    width: 100vw;
}

.pageContainer_pageContainer__omPgW > div {
    width: 100%;
}

.pageContainer_pageContainer__omPgW > div > div {
    padding: 0 20px;
    max-width: var(--main-container-max-width);
    margin-left: auto;
    margin-right: auto;
}

.pageContainer_pageContainer__omPgW * {
    box-sizing: border-box;
}

@media screen and (min-width: 481px) {
    .pageContainer_pageContainer__omPgW > div > div {
        padding: 0 50px;
    }
}

@media screen and (min-width: 769px) {
    .pageContainer_pageContainer__omPgW > div > div {
        padding: 0 90px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .pageContainer_pageContainer__omPgW > div > div {
        padding: 0px 90px;
        max-width: none;
    }
}

@media screen and (min-width: 1350px) {
    .pageContainer_pageContainer__omPgW > div > div {
        padding: 0px;
        max-width: var(--main-container-max-width);
    }
}
.button_button__3t9xL {
    width: 100%;
}

.button_button__3t9xL span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.button_buttonContainer__TisE0 {
    margin-bottom: 10px;
    margin-right: unset;
}

.button_buttonContainer__TisE0:last-child {
    margin-bottom: 0;
    margin-right: unset;
}

@media screen and (min-device-width: 768px) {
    .button_buttonContainer__TisE0 {
        margin-right: 40px;
    }

    .button_button__3t9xL {
        width: unset;
    }
}
.h2_title__3qX5m{
    font-family: "DomaineDisplay";
    color: var(--color-black) !important;
    font-size: 36px !important;
    line-height: 44px !important;
    font-weight: 500 !important;
    margin-bottom: 25px !important;
}
.pageTitle_title__17TJY {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
}

.pageTitle_title__17TJY *{
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
    color: inherit;
}

@media (min-width: 768px) {
    .pageTitle_title__17TJY {
        font-size: 54px !important;
        line-height: 64px !important;
        margin-top: 145px;
        margin-bottom: 20px !important;
    }

    .pageTitle_title__17TJY *{
        font-size: 54px !important;
        line-height: 64px !important;
        margin-top: 145px;
        margin-bottom: 20px !important;
        color: inherit;
    }
}
.paragraph_text__1rQUF {
    font-size: 16px;
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    color: var(--color-secondary);
}

@media (min-width: 481px) {
    .paragraph_text__1rQUF {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (min-width: 769px) {
    .paragraph_text__1rQUF {
        font-size: 26px;
        line-height: 34px;
    }
}

@media (min-width: 1025px) {
    .paragraph_text__1rQUF {
        font-size: 26px;
    }
}
.intro_introContainer__8goD4 {
  background-color: #ebebeb;
  padding-bottom: 40px !important;
  position: relative;
  margin-bottom: 60px !important;
  background-size: cover;
  background-position: center;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 100vw !important;
}

.intro_introContainer__8goD4 > div:first-child {
  max-width: var(--main-container-max-width);
  width: 100%;
}

.intro_backgroundContainer__2KjzQ {
  position: absolute !important;
  width: 100%;
}

.intro_description__3EWYI {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
}

.intro_description__3EWYI * {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
  color: inherit;
}

.intro_paragraphContainer__h7zc1 {
  width: 100%;
}

.intro_introContent__143wo{
    z-index: 1;
}

@media screen and (min-width: 768px) {
  .intro_introContainer__8goD4 {
    padding-bottom: 80px !important;
  }
}

@media screen and (min-width: 992px) {
  .intro_introContainer__8goD4 {
    padding-bottom: 100px !important;
    max-width: none !important;
  }

  .intro_introContainer__8goD4 > div:not(:last-child) {
    max-width: var(--main-container-max-width);
    margin: auto;
  }

  .intro_description__3EWYI {
    display: block;
    width: 80%;
  }

  .intro_backgroundContainer__2KjzQ > img:first-child {
    float: left;
  }

  .intro_backgroundContainer__2KjzQ > img:nth-child(2) {
    float: right;
  }
}
.menu_menuContainer__1PRDE {
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.menu_menuContainer__1PRDE:hover {
    background-color: var(--color-black);
}

.menu_menuContainer__1PRDE:hover h1 {
    color: var(--color-white) !important;
}

.menu_menuContainer__1PRDE > h1 {
    margin-bottom: 0 !important;
    margin-left: 10px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.menu_menuRow__18CXY{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.menu_menuRow__18CXY > div:not(:nth-child(3n + 1)) {
    margin-left: 0;
}


@media screen and (min-device-width: 1024px) { 
    .menu_menuRow__18CXY > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2);
    }
}

.h1_title__1COYg {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    color: var(--color-black) !important;
    font-size: 54px !important;
    line-height: 64px !important;
    margin-bottom: 25px !important;
}
.paragraph_wrapper__1cqH- p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 1024px) {
  .paragraph_wrapper__1cqH- p {
    color: var(--color-secondary);
    font-size: 18px;
    line-height: 26px;
  }
}

blockquote {
  padding: 10px 0px 10px 25px !important;
  margin: 0 0 25px !important;
  font-size: 18px;
  line-height: 26px;
  border-left: 5px solid #d5d5d5;
  display: block;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-end: 40px;
          margin-inline-end: 40px;
  color: #666
}

.quote_wrapper__3fL-y{
  margin-bottom: 25px !important;
}
mark {
  background-color: #fcf8e3;
  padding: 0.2em !important;
  color: #000;
  margin-bottom: 25px;
}

.highlight_wrapper__1DzHa p{
  margin-bottom: 0px;
}
.text_pageBody__1cT_q {
    overflow-x: auto;
}

.text_pageBody__1cT_q p img {
    object-fit: cover;
    width: 100%;
}

.text_pageBody__1cT_q strong {
    color: var(--color-black);
}

.text_pageBody__1cT_q * {
    color: var(--color-secondary);
}

@media screen and (min-device-width: 1024px) {
    .text_pageBody__1cT_q p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
}

.image_wrapper__1qLko{
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.image_wrapper__1qLko img{
  height: auto;
}
.textText_wrapper__2Hl_7 > div {
    margin: 0 !important;
    width: 100% !important;
}

.textText_wrapper__2Hl_7 > div > div:first-child {
    padding-left: 0 !important;
}

.textText_wrapper__2Hl_7 > div > div:last-child {
    padding-right: 0 !important;
}

.textText_wrapper__2Hl_7 h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.textText_wrapper__2Hl_7 p img {
  object-fit: cover;
  width: 100%;
}

.textText_wrapper__2Hl_7 p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 1024px) {
  .textText_wrapper__2Hl_7 p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .textText_wrapper__2Hl_7 p {
    color: var(--color-secondary);
    font-size: 18px;
    line-height: 26px;
  }
}

.imageText_wrapper__2ELdV {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.imageText_wrapper__2ELdV h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.imageText_wrapper__2ELdV p img {
  object-fit: cover;
  width: 100%;
}

.imageText_wrapper__2ELdV img {
  width: 100%;
}

.imageText_wrapper__2ELdV p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 1024px) {
  .imageText_wrapper__2ELdV p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .imageText_wrapper__2ELdV p {
    color: var(--color-secondary);
    font-size: 18px;
    line-height: 26px;
  }
}

.textImage_wrapper__1F2pP {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.textImage_wrapper__1F2pP h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.textImage_wrapper__1F2pP p img {
  object-fit: cover;
  width: 100%;
}

.textImage_wrapper__1F2pP img {
  width: 100%;
}

.textImage_wrapper__1F2pP p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 1024px) {
  .textImage_wrapper__1F2pP p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .textImage_wrapper__1F2pP p {
    color: var(--color-secondary);
    font-size: 18px;
    line-height: 26px;
  }
}

.imageImage_wrapper__ek2GA {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.imageImage_wrapper__ek2GA h1 {
  font-family: "DomaineDisplay";
  font-size: 20px;
}

.imageImage_wrapper__ek2GA p img {
  object-fit: cover;
  width: 100%;
}

.imageImage_wrapper__ek2GA img {
  width: 100%;
}

.imageImage_wrapper__ek2GA p {
  color: var(--color-secondary);
  font-size: 14px;
}

@media screen and (min-device-width: 1024px) {
  .imageImage_wrapper__ek2GA p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .imageImage_wrapper__ek2GA p {
    color: var(--color-secondary);
    font-size: 18px;
  }

  .imageImage_wrapper__ek2GA h1 {
    font-family: "DomaineDisplay";
    font-size: 26px;
  }
}

.separator_wrapper__DaMiv hr {
  margin-top: 15px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid var(--color-medium-gray);
  box-sizing: content-box;
  height: 0;
  width: 100%;
}

.video_videoContainerFull__3fRx1 {
    width: 100%;
}

.video_videoContainerHalf__39lci {
    width: 50%;
}

.video_videoContainer__3HQMq > * {
    width: 100%;
}
.layout_main__1cjfM {
    min-height: calc(100vh - 631px);
    margin-bottom: 50px;
    background: var(--color-white);
}

.layout_noMargin__2BBVD {
    margin: 0;
}

@media screen and (min-width: 769px) { 
    .layout_main__1cjfM {
        min-height: calc(100vh - 194px);
        background: var(--color-white);
    }
}

:root {
    --main-container-max-width: 1170px;
    --header-height: 58px;
    --internal-padding: 0.2rem;
    --regular-padding: 0.5rem;
    --font-size-page-description: 15px;
    --line-height-page-description: 21px;
}

@media (min-width: 992px) {
    :root {
        --font-size-page-description: 18px;
        --line-height-page-description: 26px;
    }
}
#root>div>div>.header_header__3ibMk {
    background: transparent;
    border-bottom: 1px solid black;
    height: var(--header-height);
}

.header_headerGrid__15UB- {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--main-container-max-width);
    padding: 0 90px;

}

.header_menuGrid__35RHE {
    display: grid;
    grid-template-columns: 20px 100px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: start;
            justify-content: start;
    grid-gap: 20px;
    gap: 20px;
}

.header_appsGrid__1te9R {
    display: grid;
    grid-template-columns: 20px 20px 32px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: end;
            justify-content: end;
    grid-gap: 20px;
    gap: 20px;
}

.header_appsGrid__1te9R .header_icon__1rxkD:hover {
    opacity: 0.5;
}

.header_icon__1rxkD {
    cursor: pointer;
}

.header_notificationIcon__3CF1N {
    width: 20px;
    height: 20px;
}

.header_search___uIw3 {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
}

.header_apps__1XhyK {
    display: none;
}

.header_logo__7w_7g {
    width: 100px;
}

.header_end__qTUCj {
    justify-self: end;
}

.header_start__C6beB {
    justify-self: start;
}

.header_dropdown__2EBQb {
    top: 60px !important;
    z-index: 10000;
    /* Workaround to fix 4px on left */
    /* width: 101%; */
    width: 100%;
    margin-top: -2px !important;
    position: fixed !important
}

.header_dropdown__2EBQb ul li {
    padding-left: 20px;
    padding-right: 20px;
}

.header_settings__2gaq8 ul li {
    text-align: right;
}

.header_dropdown__2EBQb ul li span {
    font-size: 16px;
    font-style: normal;
    line-height: 19px;
    letter-spacing: 0em;
}

.header_dropdown__2EBQb ul li:not(:first-child):hover {
    background-color: var(--color-medium-gray) !important;
    cursor: pointer;
}

.header_appsDropdown__1LciA {
    position: fixed !important;
    top: var(--header-height) !important;
    z-index: 10000;
    max-width: 400px;
    border-right: 1px solid var(--color-medium-gray);
    border-left: 1px solid var(--color-medium-gray);
    border-bottom: 1px solid var(--color-medium-gray);
}

.header_dropdown__2EBQb .header_menuContainer__3wqHe {
    position: relative;
    height: calc(100vh - 57px);

    background-color: #f4f4f4;
}

.header_overflowActive__L0mLR {
    overflow-y: scroll;
}

.header_dropdown__2EBQb .header_menu__3a4Vt {
    background-color: #f4f4f4;
    padding-top: 20px !important;
}

.header_noNotification__2-TX2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.header_noNotification__2-TX2>span {
    color: var(--color-secondary) !important;
}

.header_noscroll__3Rkty {
    overflow: hidden;
}

.header_burger__1y_PP {
    width: 16px;
    height: 16px;
    margin-right: 0;
}

.header_desktop__2BXi- {
    display: none;
}

.header_mobile__3G14i {
    display: -webkit-flex;
    display: flex;
}

.header_sidebarOpen__sU-I_ {
    background-color: var(--color-white) !important;
}

.header_sidebarOpen__sU-I_>.header_headerGrid__15UB-:last-child {
    opacity: 0 !important;
    width: 50% !important;
}

.header_searchbarGrid__96UOn {
    display: none;
}

.header_focusedSearchbarHeader__eb430>.header_headerGrid__15UB->.header_searchbarGrid__96UOn {
    display: grid;
    grid-template-columns: auto 45px;
}

.header_searchBar__2uJrZ {
    display: -webkit-flex;
    display: flex;
}

.header_searchBar__2uJrZ .ant-input-prefix {
    margin-right: 16px !important;
}

.header_searchBar__2uJrZ .ant-input-affix-wrapper {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.header_searchBar__2uJrZ .ant-input-affix-wrapper:focus,
.header_searchBar__2uJrZ .ant-input-affix-wrapper:hover {
    box-shadow: none !important;
    border: none !important;
}

.header_searchBar__2uJrZ input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 22px;
    color: black;
    background-color: transparent !important;
}

.header_searchBar__2uJrZ input::-webkit-input-placeholder {
    color: var(--color-light-gray-2);
}

.header_searchBar__2uJrZ input:-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.header_searchBar__2uJrZ input::placeholder {
    color: var(--color-light-gray-2);
}

.header_searchBar__2uJrZ input::-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.header_focusedSearchbarHeader__eb430>.header_headerGrid__15UB- {
    grid-template-columns: auto !important;
}

.header_focusedSearchbarHeader__eb430>div>.header_menuGrid__35RHE {
    display: none !important;
}

.header_focusedSearchbarHeader__eb430>div>.header_burger__1y_PP {
    display: none !important;
}

.header_focusedSearchbarHeader__eb430>div>.header_apps__1XhyK {
    display: none !important;
}

.header_focusedSearchbarHeader__eb430>div>.header_appsGrid__1te9R {
    display: none !important;
}

.header_close__2B6Ui {
    -webkit-align-self: center;
            align-self: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    justify-self: flex-end;
}

.header_close__2B6Ui path {
    stroke: var(--color-light-gray-2);
}

.header_close__2B6Ui:hover path {
    stroke: black;
}

.header_searchbarDropdownChevron__1Ym6D {
    margin-right: 4px;
}

.header_focusedSearchbarHeader__eb430 {
    -webkit-filter: unset !important;
            filter: unset !important;
    transition: 0.1s linear;
    background-color: var(--color-white) !important;
    border-bottom: 1px solid var(--color-secondary) !important;
}

.header_searchOverlay__3Sves {
    transition: visibility 0s, opacity 0.2s linear;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
}

.header_displaySearchOverlay__2nMZL {
    visibility: visible;
    opacity: 1;
}

.header_searchbarDropdown__Fs4Cs {
    top: 119px !important;
    z-index: 100000 !important;
    position: fixed !important;
}

.header_searchbarDropdown__Fs4Cs .ant-dropdown-menu {
    padding: 5px 0 !important;
}

.header_searchbarDropdown__Fs4Cs .ant-dropdown-menu-item {
    color: var(--color-secondary);
    background-color: white !important;
    padding: 8px 12px 8px 20px !important;
}

.header_searchbarDropdown__Fs4Cs .ant-dropdown-menu-item>span {
    color: var(--color-secondary);
}

.header_searchbarDropdown__Fs4Cs .ant-dropdown-menu-item-selected>span {
    color: black
}

.header_searchbarDropdown__Fs4Cs .ant-dropdown-menu-item-active>span {
    color: black
}

.header_searchbarDropdownTitle__j2sap {
    line-height: 60px;
    font-family: "GT-America";
    margin-right: 10px;
    text-align: center;
    min-width: 120px;
    font-size: 16px !important;
    color: var(--color-light-gray-2);
}

.header_logout__xlFMi {
    padding-bottom: 20px !important;
}

.lightTextHeader .header_searchbarDropdownTitle__j2sap {
    color: white;
}

.lightTextHeader .header_searchbarDropdownRow__2p5-l svg path {
    fill: white !important;
}

.lightTextHeader .header_searchbarDropdownRow__2p5-l {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.lightTextHeader .header_searchBar__2uJrZ input::-webkit-input-placeholder {
    -webkit-filter: invert(1);
            filter: invert(1);
    color: white;
}

.lightTextHeader .header_searchBar__2uJrZ input:-ms-input-placeholder {
    filter: invert(1);
    color: white;
}

.lightTextHeader .header_searchBar__2uJrZ input::placeholder {
    -webkit-filter: invert(1);
            filter: invert(1);
    color: white;
}

.lightTextHeader .header_searchBar__2uJrZ input::-ms-input-placeholder {
    filter: invert(1);
    color: white;
}

.profilePicture {
    -webkit-filter: invert(0);
            filter: invert(0)
}

.header_focusedSearchbarHeader__eb430 .header_searchbarDropdownTitle__j2sap {
    -webkit-filter: unset !important;
            filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchbarDropdownRow__2p5-l {
    -webkit-filter: unset !important;
            filter: unset !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchBar__2uJrZ input::-webkit-input-placeholder {
    -webkit-filter: unset !important;
            filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchBar__2uJrZ input:-ms-input-placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchBar__2uJrZ input::placeholder {
    -webkit-filter: unset !important;
            filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchBar__2uJrZ input::-ms-input-placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__eb430 .header_searchbarDropdownRow__2p5-l svg path {
    fill: black !important;
}


.header_searchbarDropdownRow__2p5-l {
    border-bottom: #D5D5D5 0.75px solid;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
    cursor: pointer;
}

.header_searchContainer__3H_XL {
    padding-left: 20px;
    padding-right: 20px;
}

.header_noResultsMesage__2Eby9 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px 0 0 0;
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px !important;
}

.header_centerSpinner__2t8Br {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.header_searchResultTitle__7QyEE {
    font-family: "DomaineDisplay";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px !important;
    color: black;
    margin-right: 5px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header_searchResultTitleClickable__3DfRP {
    cursor: pointer;

}

.header_searchResultTitleClickable__3DfRP:hover {
    -webkit-text-decoration: underline solid var(--color-black) from-font;
            text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.header_searchResultType__2A_6- {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px !important;
    color: var(--color-light-gray-2);
}

.header_searchResultsItem__3MJ2C {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 15px;
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
}

.header_containerLoading__3uJ0G {
    pointer-events: none;
    opacity: 0.5;
}

.header_searchResultsContainer__36T1c {
    margin-top: 20px;
    height: 100vh;
    overflow: auto;
}

.header_searchResultTitleHighlight__2Ixuw {
    background-color: black;
    color: white;
    padding: unset !important;

}

.header_hideSearchIcon__26VXL {
    pointer-events: none;
    opacity: 0;
}

.header_seeAllCategories__1TPAX {
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--color-disabled);
    background-color: var(--color-white);
}

.header_seeAllCategories__1TPAX>div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}

.header_notificationIconContainer__betf_ {
    height: 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.header_newNotification__1-Pcj {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--color-danger);
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 100;
}

.header_menu__3a4Vt li:last-child {
    margin-bottom: 60px !important
}

.header_tabsContainer__3e7dH {
    position: absolute;
    z-index: 1;
    top: 50px;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}


@media screen and (min-width: 481px) {

    .header_searchContainer__3H_XL {
        padding-left: 50px;
        padding-right: 50px;
    }

    .header_headerGrid__15UB- {
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;
    }

    .header_menuGrid__35RHE {
        display: grid;
        grid-template-columns: 20px 115px;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_menu__3a4Vt li:last-child {
        margin-bottom: 0px !important
    }
}

@media screen and (min-width: 692px) {
    .header_menuGrid__35RHE {
        display: grid;
        grid-template-columns: 20px 115px;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_appsGrid__1te9R {
        display: grid;
        grid-template-columns: 20px 20px 32px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: end;
                justify-content: end;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_dropdown__2EBQb {
        top: var(--header-height) !important;
        max-width: 320px;
        max-height: 505px;
        margin-top: 0 !important;
        box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, .1);
    }

    .header_dropdown__2EBQb>.header_menuContainer__3wqHe {
        max-height: 505px;
        position: relative;
        height: unset;
    }

    .header_menuContainer__3wqHe>.header_menu__3a4Vt {
        padding-bottom: 0 !important;

    }

    .header_seeAllCategories__1TPAX {
        position: -webkit-sticky;
        position: sticky;
        top: calc(100% - 60px);
    }

    .header_settings__2gaq8 {
        max-width: 185px;
    }

    .header_settings__2gaq8 ul li {
        text-align: left;
    }
}

@media screen and (min-width: 768px) {

    .header_searchbarDropdown__Fs4Cs {
        top: 50px !important;
    }

    .header_hideSearchbar__37zFw {
        pointer-events: none;
        border-bottom: 1px black solid !important;
    }

    .header_hideSearchbar__37zFw div {
        opacity: 0;
    }

    .header_hideSearchbar__37zFw input {
        display: none;
    }

    .header_focusedSearchbarHeader__eb430 {
        border-bottom: 1px solid #D2D2D2 !important;
    }

    .header_searchbarGrid__96UOn {
        display: grid;
        grid-template-columns: 190px auto;
        -webkit-align-items: center;
                align-items: center;
        z-index: 1000000;
        height: 100%;
    }

    .header_noResultsMesage__2Eby9 {
        display: unset;
        -webkit-justify-content: unset;
                justify-content: unset;
        margin-left: 65px;
    }

    .header_searchbarDropdownTitle__j2sap {
        line-height: 34px;
    }

    .header_searchBar__2uJrZ .ant-input-affix-wrapper {
        padding: 0 0 0 30px !important;
    }

    .header_close__2B6Ui {
        justify-self: unset;
        margin-left: 50px;
    }

    .header_searchbarDropdownRow__2p5-l {
        margin-bottom: unset;
        border-right: var(--color-light-gray-2) 0.75px solid;
        border-bottom: unset;
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .lightTextHeader .header_searchbarDropdownRow__2p5-l {
        border-right: white 0.75px solid;
    }

    .header_focusedSearchbarHeader__eb430 .header_searchbarDropdownRow__2p5-l {
        border-right: var(--color-light-gray-2) 0.75px solid !important;
    }

    .header_focusedSearchbarHeader__eb430>.header_headerGrid__15UB->.header_searchbarGrid__96UOn {
        border-bottom: 1px black solid !important;
        grid-template-columns: 190px auto 115px 24px !important;
    }

    .header_searchButton__DfNsb {
        font-family: "GT-America";
        font-size: 14px !important;
        font-weight: 400;
        color: black;
        text-align: end;
        cursor: default;
        opacity: 0;
    }

    .header_displaySearchButton__dcEhE {
        cursor: pointer;
        opacity: 1 !important;
    }

    .header_searchButton__DfNsb:hover {
        -webkit-text-decoration: underline solid var(--color-black) from-font;
                text-decoration: underline solid var(--color-black) from-font;
        text-underline-offset: 4px;
    }

    .header_searchContainer__3H_XL {
        padding-left: calc(90px - 24px);
        padding-right: calc(90px - 24px);
        width: calc(var(--main-container-max-width) + 24px + 24px);
        grid-template-columns: 190px auto;
        display: grid;
    }

    .header_searchSuggestionsMessage__38E1G {
        margin: 20px 0 0 0;
        font-family: "GT-America";
        font-size: 14px;
        font-weight: 300;
        line-height: 20px !important;
        text-align: center;
    }

    .header_searchResultsContainer__36T1c {
        margin-left: 65px;
    }

    section>.header_header__3ibMk {
        height: 80px;
        padding: 0 40px !important;
    }

    .header_search___uIw3 {
        display: none;
    }

    .header_apps__1XhyK {
        display: -webkit-flex;
        display: flex;
        margin-left: 20px;
        margin-right: 0;
    }

    .header_headerGrid__15UB- {
        padding-left: calc(90px);
        padding-right: calc(90px);
        grid-template-columns: 24px 125px auto 124px 24px;
        grid-gap: 0;
        gap: 0;
    }

    .header_appsGrid__1te9R {
        display: grid;
        grid-template-columns: 20px 32px;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: end;
                justify-content: end;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_desktop__2BXi- {
        display: -webkit-flex;
        display: flex;
    }

    .header_mobile__3G14i {
        display: none;
    }

    .header_burger__1y_PP {
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
  
}

@media screen and (min-width: 1271px) {

    .header_searchContainer__3H_XL {
        max-width: none;
        padding-right: calc(90px - 24px);
        padding-left: calc(90px - 24px);
    }

    .header_headerGrid__15UB- {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--main-container-max-width);
        padding: 0 90px;
    }
   
}

@media screen and (min-width: 1350px) {

    .header_searchContainer__3H_XL {
        width: calc(var(--main-container-max-width) + 24px + 24px);
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
    }

    .header_headerGrid__15UB- {
        width: calc(var(--main-container-max-width));
        padding-right: 0;
        padding-left: 0;    
    }
}
.footerContent_footer__k3yR5 {
    background: none !important;
    background-color: var(--color-black) !important;
    position: relative;
    background-repeat: no-repeat !important;
}

.footerContent_title__3zfg1 h1 {
    color: var(--color-secondary) !important;
    margin-bottom: 1rem !important;
}

.footerContent_title__3zfg1:first-child {
    margin-top: 40px;
}

.footerContent_linksContainer__WmATU span {
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
    margin-top: 1rem !important;
}

.footerContent_icon__35TIj path {
    fill: var(--color-white);
}

.footerContent_copyright__F5Wki>span {
    font-size: 12px !important;
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
}

.footerContent_footerBanner__1T0De {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.footerContent_footerBanner__1T0De img {
    position: absolute;
    bottom: 0;
    right: -100px;
    width: 140%
}

@media (min-width:420px) {
    .footerContent_footerBanner__1T0De img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 120%
    }
}

@media (min-width: 768px) {
    .footerContent_icon__35TIj {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-align-items: center;
                align-items: center;
    }
    .footerContent_footerBanner__1T0De img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 100%
    }
}

@media (min-width: 992px) {
    .footerContent_copyright__F5Wki {
        margin-left: 30px;
    }
}

@media (min-width: var(--main-container-max-width)) {
    .footerContent_footerBanner__1T0De img {
        display: none !important;
    }
}
.footer_container__3aySK {
    background-color: var(--color-black);
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.footer_container__3aySK>div {
    overflow: hidden !important;
}

.footer_footerBanner__3Xqnf {
    display: none;
}

@media (min-width: var(--main-container-max-width)) {
    .footer_footerBanner__3Xqnf {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 1000;
        padding: 0 !important;
        right: 0;
    }
    .footer_footerBanner__3Xqnf img {
        width: 600px;
    }
}
.backToTop_backTop__2o6eS {
    display: none;
}

.backToTop_backTop__2o6eS>div {
    border: 1px solid white;
}

.backToTop_tooltip__1n02N>div>div:nth-child(2) {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    padding: 12px 10px !important;
    border: 1px solid white;
}

@media (min-width: 1024px) {
    .backToTop_backTop__2o6eS {
        display: block
    }
}
:root  {
    --color-black: #000;
    --color-white: #fff;

    --color-danger: #f66168;
    --color-disabled: #d5d5d5;
    --color-success: #8AD6AF;
    --color-warning: #FAC362;
    --color-call-action: #A0C6D2;

    --color-secondary: #666666;
    --color-tertiary: #9A9A9A;

    --color-selection: #f0f0f0;
    --color-light-gray: #f2f2f2;
    --color-light-gray-2: #9E9E9E;
    --color-medium-gray: #d5d5d5;
    --color-gray: #808080;
    --color-dark-gray: #484848;

    --color-mark: #feffe6;
    --antd-wave-shadow-color: #000;
}
:root {
    --main-container-max-width: 1170px;
    --header-height: 58px;
    --internal-padding: 0.2rem;
    --regular-padding: 0.5rem;
    --font-size-page-description: 15px;
    --line-height-page-description: 21px;
}

@media (min-width: 992px) {
    :root {
        --font-size-page-description: 18px;
        --line-height-page-description: 26px;
    }
}
container h1 {
    font-family: "DomaineDisplay";
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    margin-bottom: 25px;
}
container h2 {
    font-family: "DomaineDisplay";
    font-size: 30px;
    line-height: 38px;

    font-weight: 500;
    margin-bottom: 25px;
}
container h3 {
    font-family: "DomaineDisplay";
    font-size: 26px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 25px;
}
container h4 {
    font-family: "DomaineDisplay";
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 25px;
}
container h5 {
    font-family: "DomaineDisplay";
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px;
}
container h6 {
    font-family: "DomaineDisplay";
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 25px;
}
container hr {
    margin: 15px auto 40px;
    border-top: 1px solid var(--color-medium-gray);
}
container strong {
    font-weight: 500;
}

container a {
    color: var(--color-secondary);
    -webkit-text-decoration: underline 1px solid var(--color-secondary);
            text-decoration: underline 1px solid var(--color-secondary);
    text-underline-offset: 4px;
}

container a:hover {
    color: var(--color-black);
    -webkit-text-decoration: underline 1px solid var(--color-black);
            text-decoration: underline 1px solid var(--color-black);
}

container .withAuthentication_noDecoration__RKgp8 {
    text-decoration: none;
}

container .withAuthentication_noDecoration__RKgp8:hover {
    text-decoration: none;
}

container .withAuthentication_noDecoration__RKgp8:hover > h1 {
    color: var(--color-black);
    -webkit-text-decoration: underline 1px solid var(--color-black);
            text-decoration: underline 1px solid var(--color-black);
}

container p {
    font-family: "GT-America";
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--color-secondary);
    margin-bottom: 25px;
}

container li {
    font-family: "GT-America";
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 10px;
}

container button {
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 25px;
}

container .withAuthentication_ql-align-right__3nS4F {
    text-align: right;
}

container .withAuthentication_ql-align-left__nGbjO {
    text-align: left;
}

container .withAuthentication_ql-align-center__fHuaR {
    text-align: center;
}

container .withAuthentication_ql-align-justify__3Ums0 {
    text-align: justify;
}

container .withAuthentication_darkTextHeader__2Y2pa {
    -webkit-filter: invert(0);
            filter: invert(0);
}

container .withAuthentication_lightTextHeader__3Dxg8 {
    -webkit-filter: invert(1);
            filter: invert(1);
}

container #withAuthentication_mobileCarouselArrowRight__23ydI {
    right: 0 !important;
    top: -65px !important;
}

container #withAuthentication_mobileCarouselArrowLeft__3qFKM {
    left: unset !important;
    right: 75px !important;
    top: -65px !important;
}

container #withAuthentication_desktopCarouselArrowLeft__AuG0e {
    left: -50px !important;
}

container #withAuthentication_desktopCarouselArrowRight__1rkjp {
    right: -50px !important;
}

@media (min-width: 768px) {
    container h1 {
        font-size: 54px;
        line-height: 64px;
    }

    container h2 {
        font-size: 36px;
        line-height: 44px;
    }

    container p {
        font-size: 18px;
        line-height: 26px;
    }
}

/* Workaround to change the default ant color, customizing webpack breaks the PWA */
/* More info here: https://ant.design/docs/react/customize-theme */

container .withAuthentication_ant-typography__3mllz a {
    color: black !important
}

container .withAuthentication_ant-input-affix-wrapper-focused__HG1Fi {
    border-color: black !important;
}

container .withAuthentication_ant-input-affix-wrapper__2_LrK:not(.withAuthentication_ant-input-affix-wrapper-disabled__1HXk9):hover {
    border-color: black !important;
}

container .withAuthentication_ant-input-affix-wrapper-focused__HG1Fi, .withAuthentication_ant-input-affix-wrapper__2_LrK:focus {
    border-color: #0d0c0c;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
}

container .withAuthentication_ant-spin-dot-item__fgJix {
    background-color: black !important
}

container .withAuthentication_ant-spin__2KGFu {
    color: black !important
}
