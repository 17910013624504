:root {
    --padding-small: 0.5rem;
    --padding: 0.7rem;  
    --padding-medium: 1.5rem;
    --padding-large: 2rem;

    --margin-internal: 0.5rem;
    --margin-small: 0.75rem;
    --margin: 1rem;
    --margin-medium: 1.75rem;
    --margin-large: 2.25rem;
    --margin-subpage: 3.5rem;

    --box-shadow-hover: 0px 2px 1px 0px var(--color-gray-lighter);
    --box-radius: 2px;
    --button-radius: 3px;

    --radius: 3px;
}
