.button {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    border-radius: 0 !important;
    transition: none !important;
    height: unset !important;
    padding: 10px 24px !important;
}

.primary-enabled {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-black) !important;
}

.primary-danger {
    background-color: var(--color-danger) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-danger) !important;
}

.primary-disabled {
    background-color: var(--color-disabled) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-disabled) !important;
}

.default-enabled {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-black) !important;
}

.default-danger {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-danger) !important;
}

.default-disabled {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-disabled) !important;
}

.text-enabled {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-white) !important;
    box-shadow: none !important;
}

.text-danger {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-white) !important;
}

.text-disabled {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-white) !important;
}

@media screen and (min-width: 768px) {

    .button {
        font-size: 14px !important;
    }

}