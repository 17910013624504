.pageBody {
    overflow-x: auto;
}

.pageBody p img {
    object-fit: cover;
    width: 100%;
}

.pageBody strong {
    color: var(--color-black);
}

.pageBody * {
    color: var(--color-secondary);
}

@media screen and (min-device-width: 1024px) {
    .pageBody p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
}
