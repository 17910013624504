.customRichTextInput :global(.ql-editor) {
    min-height: 300px;
}

.customRichTextInput {
    min-width: 100%;
}

.customRichTextInput :global(.ql-editor) {
    min-height: 200px;
}

.customRichTextInput :global(.ql-editor) p {
    margin-bottom: 0px !important;
}

.customRichTextInput :global(.ql-editor) h1,
.customRichTextInput :global(.ql-editor) h2,
.customRichTextInput :global(.ql-editor) h3 {
    margin-top: 0px !important;
}