.menuContainer {
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuContainer:hover {
    background-color: var(--color-black);
}

.menuContainer:hover h1 {
    color: var(--color-white) !important;
}

.menuContainer > h1 {
    margin-bottom: 0 !important;
    margin-left: 10px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.menuRow{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.menuRow > div:not(:nth-child(3n + 1)) {
    margin-left: 0;
}


@media screen and (min-device-width: 1024px) { 
    .menuRow > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2);
    }
}
