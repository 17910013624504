.cardContainer {
    max-width: 270px;
}

.cardContainer .cover {
    max-height: 230px;
    border: 1px solid #EBEBEB;
    border-bottom: unset;
}

.cardContainer :global(.ant-card-body) {
    padding: 15px;
    border-top: unset;
    cursor: pointer;
    border-bottom: 1px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
}

.cardContainer :global(.ant-card-meta-title) {
    font-family: "GT-America";
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    height: 52px;
    margin-bottom: 10px !important;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.cardContainer .lastUpdated {
    font-family: "GT-America";
    font-size: 10px !important;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary);
    margin-top: unset;
}

.cardContainer .tagTitle {
    font-family: "GT-America";
    font-size: 12px;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary) !important;
    margin-top: unset;
    margin-bottom: 5px !important;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardContainer.cardThumb :global(.ant-card-cover)::before {
    content: "";
    position: absolute;
    left: 0; 
    right: 0;
    top: 0; 
    bottom: 0;
    max-height: 230px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/svg/play.svg');
}

@media screen and (min-width: 769px) {
    .cardContainer {
        max-width: 270px;
    }

    .cardContainer .cover {
        max-height: 230px;
    }

    .cardContainer .lastUpdated {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 992px) {
    .cardContainer {
        max-width: 245px;
    }

    .cardContainer .cover, .cardContainer.cardThumb :global(.ant-card-cover)::before {
        max-height: 215px;
    }
}

@media screen and (min-width: 1200px) {
    .cardContainer {
        max-width: 225px;
    }

    .cardContainer .cover, .cardContainer.cardThumb :global(.ant-card-cover)::before {
        max-height: 200px;
    }
}

@media screen and (min-width: 1440px) {
    .cardContainer {
        max-width: 270px;
    }

    .cardContainer .cover, .cardContainer.cardThumb :global(.ant-card-cover)::before {
        max-height: 230px;
    }
}

@media (hover: hover) {
    .cardContainer {
        cursor: pointer;
    }
    
    .cardContainer:hover .cover {
        border-bottom: 1px solid var(--color-black) !important;
    }

    .cardContainer:hover {
        background-color: var(--color-black);
    }
    
    .cardContainer:hover :global(.ant-card-meta-title), .cardContainer:hover .lastUpdate, .cardContainer:hover :global(.ant-card-body) {
        color: var(--color-white) !important;
    }

    .cardContainer.cardThumb:hover g {
        stroke: var(--color-white) !important;
    }

    .cardContainer.cardThumb:hover g path {
        stroke: var(--color-white) !important;
    } 

    .cardContainer:hover > div:nth-child(2) {
        border-bottom: 1px solid var(--color-black);
        border-left: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
    }
}
