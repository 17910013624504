.status {
    display: inline-block;
    padding: 3px 4px;
    font-size: 0.9rem;
    line-height: 1em;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    text-transform: capitalize;
}

.draft {
    background: var(--color-red-danger);
    color: var(--color-white);
}

.published {
    background: var(--color-green-sucess);
    color: var(--color-white);
}

.unpublished, .archived {
    background: var(--color-blue-action) ;
    color: var(--color-white);
}

