div .toolbar {
    justify-content: start;
    align-items: center;
}

.rtfContainerTab, .rtfContainerDescription {
    min-width: 100%
}

.rtfContainerTab :global(.ql-editor) {
    min-height: 45px;
}

.rtfContainerDescription :global(.ql-editor) {
    min-height: 135px;
}