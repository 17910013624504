.phonePaginationContainer, .paginationContainer {
    justify-content: flex-end !important;
}

.phoneBottomReachedMessage {
    justify-content: center !important;
    font-weight: 600 !important;
    font-family: "DomaineDisplay";
}

.paginationContainer :global(.ant-pagination-prev) {
    width: 40px !important;
    height: 50px !important;
    margin-right: 10px;
}

.paginationContainer :global(.ant-pagination-next) {
    width: 35px !important;
    height: 50px !important;
    margin: 0;
}

.paginationContainer :global(.ant-pagination-prev > svg) {
    width: 100%;
    height: 50px;
}

.paginationContainer :global(.ant-pagination-next > svg) {
    width: 100%;
    height: 50px;
}

.paginationContainer :global(.ant-pagination-disabled > li > svg > path) {
    stroke: #D2D2D2 !important;
}

.paginationContainer :global(.ant-pagination-disabled > svg > path) {
    stroke: #D2D2D2 !important;
}

.paginationContainer :global(.ant-pagination-simple-pager) {
    font-family: "DomaineDisplay";
    font-weight: 600;
    font-size: 20px !important;
    color: #979797 !important;
    height: auto !important;
    margin-right: 0px !important;
    width: 45%;
}

.paginationContainer :global(.ant-pagination-simple-pager > input) {
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 40px !important;
    border: none !important;
    cursor: default;
    pointer-events: none;
    width: 55% !important;
    margin: unset !important;
    padding: unset !important;
}

.paginationContainer :global(.ant-pagination-simple-pager > input:disabled) {
    background: unset !important;
    color: #D2D2D2 !important;
}

.paginationContainer :global(.ant-pagination-slash) {
    margin: 0 5px 0 0;
}

.paginationContainer>ul>li:nth-child(2)>input {
    background-clip: text;
}