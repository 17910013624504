:root {
    --color-black: #000000;
    --color-gray: #757575;
    --color-gray-dark: #525252;
    --color-gray-light: #9E9E9E;
    --color-gray-lighter: #f8f8f8;

    --color-gray-sidebar-dark: #323232;
    --color-gray-sidebar: #D5D5D5;
    --color-gray-sidebar-light: #F0F0F0;

    --color-green-sucess: #8AD6AF;
    --color-blue-action: #A0C6D2;
    --color-yellow-warning: #FAC362;;
    --color-red-danger: #F66168;

    --color-white: #FFFFFF;
}
