
.menuFilterContainer {
    padding: 20px !important;
    top: 15px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item):first-child {
    margin-bottom: 20px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item) {
    padding: 0;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item) > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .selectGroup {
    display: inline-flex;
    flex-direction: column;
}

.selectGroup > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filterContainer {
    display: flex;
    align-items: center;
}

.filterContainer > div > .divider {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.pointer {
    cursor: pointer;
}

.filterLabel {
    display: block;
    width: 100%;
}

.filterLabel > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chevron {
    margin-left: 15px;
    width: 14px;
}

.clearButton {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item):hover {
    background-color: unset
}

.dropdown {
    padding-right: 20px;
    width: 290px;
    display: flex;
}

.selectGroup > :global(.ant-checkbox-wrapper) {
    margin-left: 0;
}