@font-face {
    font-family: "GTAmerica-Thin";
    src: url("./fonts/GT-America-Extended-Thin.ttf");
}

@font-face {
    font-family: "GTAmerica-Regular";
    src: url("./fonts/GT-America-Extended-Regular.ttf");
}

@font-face {
    font-family: "GTAmerica-Bolder";
    src: url("./fonts/GT-America-Extended-Black.ttf");
}

@font-face {
    font-family: "GTAmerica-Bold";
    src: url("./fonts/GT-America-Extended-Bold.ttf");
}

@font-face {
    font-family: "GTAmerica-Medium";
    src: url("./fonts/GT-America-Extended-Medium.ttf");
}

@font-face {
    font-family: "GTAmerica-Ultra-Light";
    src: url("./fonts/GT-America-Extended-Ultra-Light.ttf");
}

@font-face {
    font-family: "GTAmerica-Light";
    src: url("./fonts/GT-America-Extended-Light.ttf");
}

@font-face {
    font-family: "DomaineDisplayWeb";
    src: url("./fonts/DomaineDisplayWeb-Regular.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 300;
    src: url("./fonts/GT-America-Extended-Light.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 400;
    src: url("./fonts/GT-America-Extended-Regular.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 500;
    src: url("./fonts/GT-America-Extended-Medium.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 600;
    src: url("./fonts/GT-America-Extended-Bold.woff");
}


@font-face {
    font-family: "DomaineDisplay";
    font-weight: 400;
    src: url('./fonts/DomaineDisplayWeb-Regular.woff');
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 500;
    src: url('./fonts/DomaineDisplayWeb-Medium.woff');
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 600;
    src: url('./fonts/DomaineDisplayWeb-Semibold.woff');
}

:root {
    --font-family: "GTAmerica-Light";
    --font-family-domaine: "DomaineDisplayWeb";

    --ratio: 1.15;
    --p: 0.85rem;
    --input: var(--p);

    --caption: calc(var(--p) / var(--ratio));
    --subcaption: calc(var(--caption) / var(--ratio));

    /* FONT WEIGHT */
    --font-weight-bold: 800 !important;
    --font-weight-semibold: 700 !important;
    --font-weight-medium: 600 !important;
    --font-weight: 500 !important;
    --font-weight-regular-secondary: 400 !important;
    --font-weight-regular: 300 !important;
    --font-weight-light: 200 !important;
    --font-weight-extra-light: 100 !important;

    --font-size-h1: 3.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.15rem;
    --font-size-subtitle: 1.15rem;
    --font-size-body: 1rem;
    --font-size-caption: 0.85rem;
}
