.footer {
    background: none !important;
    background-color: var(--color-black) !important;
    position: relative;
    background-repeat: no-repeat !important;
}

.title h1 {
    color: var(--color-secondary) !important;
    margin-bottom: 1rem !important;
}

.title:first-child {
    margin-top: 40px;
}

.linksContainer span {
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
    margin-top: 1rem !important;
}

.icon path {
    fill: var(--color-white);
}

.copyright>span {
    font-size: 12px !important;
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
}

.footerBanner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.footerBanner img {
    position: absolute;
    bottom: 0;
    right: -100px;
    width: 140%
}

@media (min-width:420px) {
    .footerBanner img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 120%
    }
}

@media (min-width: 768px) {
    .icon {
        display: flex !important;
        align-items: center;
    }
    .footerBanner img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 100%
    }
}

@media (min-width: 992px) {
    .copyright {
        margin-left: 30px;
    }
}

@media (min-width: var(--main-container-max-width)) {
    .footerBanner img {
        display: none !important;
    }
}