.text {
    font-size: 16px;
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    color: var(--color-secondary);
}

@media (min-width: 481px) {
    .text {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (min-width: 769px) {
    .text {
        font-size: 26px;
        line-height: 34px;
    }
}

@media (min-width: 1025px) {
    .text {
        font-size: 26px;
    }
}