.search {
    width: 30ch;
}

@media only screen and (max-width: 600px) {
    .form {
        width: 100%;
        padding-right: 16px;
    }

    .form div {
        max-width: 100%;
    }

    .form > div:last-child {
        display: none;
    }

    .form > div:first-child {
        width: 100%;
    }

    .form > div:first-child div:last-child {
        display: none;
    }

    .form > div:first-child div:first-child {
        width: 100%;
    }
}