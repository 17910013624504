.videoContainerFull {
    width: 100%;
}

.videoContainerHalf {
    width: 50%;
}

.videoContainer > * {
    width: 100%;
}