.container {
    background-color: var(--color-black);
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.container>div {
    overflow: hidden !important;
}

.footerBanner {
    display: none;
}

@media (min-width: var(--main-container-max-width)) {
    .footerBanner {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 1000;
        padding: 0 !important;
        right: 0;
    }
    .footerBanner img {
        width: 600px;
    }
}